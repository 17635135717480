import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  // { path: '', redirectTo: '', pathMatch: 'full' },
  {
    path: '',
    loadChildren: () => import('./components/pages/home/home.module').then(m => m.HomeModule),
    data: { id: 'home', breadcrumb: 'Home' }
  },
  {
    path: 'services',
    loadChildren: () => import('./components/pages/services/services.module').then(m => m.ServicesModule),
    data: { id: 'services', breadcrumb: 'Services' }
  },
  {
    path: 'project',
    loadChildren: () => import('./components/pages/portfolio/portfolio.module').then(m => m.PortfolioModule),
    data: { id: 'project', breadcrumb: 'Our Project' }
  },
  {
    path:
      'contact',
    loadChildren: () => import('./components/pages/contact/contact.module').then(m => m.ContactModule),
    data: { id: 'contact', breadcrumb: 'Contact Us' }
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
